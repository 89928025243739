import * as React from "react"
import { Clinic } from "@app/services/Clinic"
import { Role } from "@app/services/Role"

export interface CurrentRole {
  clinicid: number
  groupid: number
  entityid: number
  roleid: number
}

export interface ChangeRoleProps {
  currentRole: CurrentRole
  roles: Role[]
  showButtons?: boolean
  showTitle?: boolean
  selectEntityRoleId?(selectedEntityRoleId: string): any
  selectClinicId?(selectedClinicId: number): any
}

export interface ChangeRoleState {
  selectedClinic: number
  selectedEntity: number
}

export default class ChangeRole extends React.Component<
  ChangeRoleProps,
  ChangeRoleState
> {
  static defaultProps = {
    showButtons: true,
    showTitle: true,
  }

  clinics: Clinic[] = []
  roles: { [index: string]: Role[] } = {}

  constructor(props: ChangeRoleProps) {
    super(props)
    this.state = {
      selectedClinic: this.props.currentRole.clinicid,
      selectedEntity: this.props.currentRole.entityid,
    }
    if (this.props.roles) {
      this.roles = this.parseRoles(
        this.props.roles.map((role) =>
          role instanceof Role ? role : new Role(role)
        )
      )
      this.clinics = this.getClinicsFromRoles(
        this.props.roles.map((role) => {
          return role instanceof Role
            ? ({
                clinic_name: role.clinicName,
                clinicid: role.clinicId,
              } as unknown as Role)
            : role
        })
      )
    }
    if (props.selectEntityRoleId) {
      props.selectEntityRoleId(
        props.currentRole.groupid +
          "-" +
          props.currentRole.entityid +
          "-" +
          props.currentRole.roleid
      )
    }
    if (props.selectClinicId) {
      props.selectClinicId(props.currentRole.clinicid)
    }
  }

  private getClinicsFromRoles(roles: Role[]): Clinic[] {
    const clinics = roles.map((role) => new Clinic(role))
    let tempMap = new Map()

    const unique = clinics.filter((clinic) => {
      const val = tempMap.get(clinic.clinicName)
      if (val) {
        if (clinic.clinicName < val) {
          tempMap.delete(clinic.clinicName)
          tempMap.set(clinic.clinicName, clinic.clinicId)
          return true
        } else {
          return false
        }
      }
      tempMap.set(clinic.clinicName, clinic.clinicId)
      return true
    })
    return unique
  }

  public filterRoles(roles: Role[], clinicId: number): Role[] {
    return roles.filter((role) => role.clinicId === clinicId)
  }

  public parseRoles(roles: Role[]): { [index: string]: Role[] } {
    const rolesById: { [index: string]: Role[] } = {}
    for (const role of roles) {
      if (
        !(
          rolesById[String(role.clinicId)] &&
          rolesById[String(role.clinicId)].length
        )
      ) {
        rolesById[String(role.clinicId)] = []
      }
      rolesById[String(role.clinicId)].push(role)
    }
    return rolesById
  }

  public selectClinic(event: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      ...this.state,
      ...{
        selectedClinic: Number.parseInt(event.target.value),
      },
    })
    if (this.props.selectClinicId) {
      this.props.selectClinicId(Number.parseInt(event.target.value))
    }
  }

  public selectEntity(event: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      ...this.state,
      ...{
        selectedEntity: Number.parseInt(event.target.value),
      },
    })
    if (this.props.selectEntityRoleId) {
      this.props.selectEntityRoleId(event.target.value)
    }
  }

  public render(): JSX.Element {
    return (
      <div>
        <form action="/user/change_role.cgi" method="post">
          {this.props.showTitle && <h2>Select Organization and Group</h2>}
          <div className="row">
            <div className="col-xs-11 offset-xs-1">
              <span className="select-container full-select">
                <select
                  key="clinicid"
                  name="clinicid"
                  onChange={(event) => this.selectClinic(event)}
                >
                  {this.clinics
                    ? this.clinics
                        .sort((a, b) =>
                          a.clinicName.localeCompare(b.clinicName)
                        )
                        .map((clinic) => (
                          <option
                            key={String(clinic.clinicId)}
                            value={clinic.clinicId}
                            selected={
                              this.props.currentRole.clinicid ===
                              clinic.clinicId
                            }
                          >
                            {clinic.clinicName}
                          </option>
                        ))
                    : ""}
                </select>
              </span>
            </div>
          </div>
          <div className="row padding-top">
            <div className="col-xs-11 offset-xs-1">
              <span className="select-container full-select">
                <select
                  key="entityid_roleid"
                  name="entityid_roleid"
                  onChange={(event) => this.selectEntity(event)}
                >
                  {this.roles && this.roles[this.state.selectedClinic]
                    ? this.roles[this.state.selectedClinic]
                        .sort((a, b) =>
                          a.groupName && b.groupName
                            ? a.groupName.localeCompare(b.groupName)
                            : 0
                        )
                        .map((role) => (
                          <option
                            key={`${role.groupId}-${role.entityId}-${role.roleId}`}
                            value={`${role.groupId}-${role.entityId}-${role.roleId}`}
                            selected={
                              this.props.currentRole.entityid === role.entityId
                            }
                          >
                            {role.groupName} - {role.roleName}{" "}
                            {role.name ? ` - ${role.name}` : ""}
                          </option>
                        ))
                    : ""}
                </select>
              </span>
            </div>
          </div>
          {this.props.showButtons && (
            <div className="row padding-top">
              <div className="col-xs-11 offset-xs-1">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Change Group
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    )
  }
}
