import React, { useEffect, useState, useCallback, memo } from "react"
import MenuItem from "@material-ui/core/MenuItem"
import ListSubheader from "@material-ui/core/ListSubheader"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import ListItemText from "@material-ui/core/ListItemText"
import css from "./ProvidersCustomSelect.module.scss"
import api from "@app/services/api"
import { orderProvidersFunc } from "@app/utils/sorts"
import { DefaultCalendarColor } from "@app/utils/constants"
import { makeStyles } from "@material-ui/core"
import { handleApiError } from "@app/utils"
import { useSelector } from "@app/models"

interface Provider {
  id: string
  name: string
  available: boolean
  color: string
  violation?: string
}

const DEFAULT_PROVIDER_COLOR = DefaultCalendarColor

interface SelectComponentProps {
  jobid: string
  edate: string
  onChange: (defaultProviderId: number) => void
  providerId?: string
  defaultProviderId?: string
  disabled?: boolean
  filterProviderIds?: number[]
  clearProviderSelection?: boolean
}

const ProvidersCustomSelectComponent: React.FC<SelectComponentProps> = ({
  jobid,
  edate,
  defaultProviderId,
  providerId,
  onChange,
  disabled,
  filterProviderIds,
  clearProviderSelection,
}) => {
  const [selectedProviderid, setSelectedProviderid] = useState<string>("")
  const [loadingProviders, setLoadingProviders] = useState(false)
  const [error, setError] = useState(false)
  const [providerList, setProviderList] = useState<Provider[]>([])
  const [availableProviderList, setAvailableProviderList] = useState<
    Provider[] | undefined
  >()
  const [unavailableProviderList, setUnavailableProviderList] = useState<
    Provider[] | undefined
  >()

  useEffect(() => {
    if (clearProviderSelection) {
      setSelectedProviderid("")
    } else {
      setSelectedProviderid(providerId ? String(providerId) : "")
    }
  }, [providerId, clearProviderSelection])

  const { providers: defaultProviders } = useSelector(
    (state) => state.groupData
  )

  useEffect(() => {
    if (
      defaultProviders.length &&
      defaultProviderId &&
      !availableProviderList
    ) {
      setAvailableProviderList(
        defaultProviders.map((provider) => ({
          id: String(provider.providerid),
          name: `${provider.display_name} - ${provider.firstname} ${provider.lastname}`,
          available: true,
          color: provider.provider_color || DEFAULT_PROVIDER_COLOR,
        }))
      )
      setSelectedProviderid(String(defaultProviderId))
    }
  }, [])

  useEffect(() => {
    if (selectedProviderid) {
      onChange(Number(selectedProviderid))
    }
  }, [selectedProviderid])

  const useStyles = makeStyles({
    customSelect: {
      height: "35px",
      "& .MuiSelect-outlined": {
        padding: "8px !important",
      },
      "& .MuiSvgIcon-root": {
        height: "16px",
        width: "16px",
      },
      "& .MuiSelect-selectMenu": {
        fontSize: "1.1rem",
        fontWeight: "600",
        lineHeight: "20px",
        color: "#767578",
      },
      "& .MuiListItemText-root": {
        maxWidth: "calc(100% - 34px)",
      },
    },
  })

  const classes = useStyles()

  const providerFilterCondition = useCallback(
    (provider: Provider) => {
      if (filterProviderIds) {
        if (provider.id === String(selectedProviderid)) {
          return true
        } else {
          return !filterProviderIds.includes(Number(provider.id))
        }
      }
      return true
    },
    [filterProviderIds, selectedProviderid]
  )

  const onOpen = () => {
    if (!availableProviderList || !unavailableProviderList) {
      setLoadingProviders(true)
      api
        .getProvidersV3({ jobid: Number(jobid), date: edate })
        .then((providerList) => {
          const { availableProviders, unavailableProviders } =
            getProvidersList(providerList)
          const filterAvailableProviders = availableProviders.filter(
            providerFilterCondition
          )
          const filterUnavailableProviders = unavailableProviders.filter(
            providerFilterCondition
          )

          setProviderList([
            ...filterAvailableProviders,
            ...filterUnavailableProviders,
          ])
          setAvailableProviderList(filterAvailableProviders)
          setUnavailableProviderList(filterUnavailableProviders)
          setLoadingProviders(false)
        })
        .catch((err) => {
          setError(true)
          handleApiError(err)
          setLoadingProviders(false)
        })
    } else {
      setProviderList([...availableProviderList, ...unavailableProviderList])
      setAvailableProviderList(
        availableProviderList.filter(providerFilterCondition)
      )
      setUnavailableProviderList(
        unavailableProviderList.filter(providerFilterCondition)
      )
    }
  }

  const getProvidersList = useCallback(
    (providersWithRules) => {
      const availableProviders: Provider[] = []
      const unavailableProviders: Provider[] = []

      providersWithRules
        .sort(orderProvidersFunc)
        .forEach((apiProvider: any) => {
          const color = apiProvider.provider_color || DEFAULT_PROVIDER_COLOR
          const providerObj: Provider = {
            id: apiProvider.providerid.toString(),
            name: `${apiProvider.display_name} - ${apiProvider.firstname} ${apiProvider.lastname}`,
            available: apiProvider.rulesApplied.length === 0,
            color,
          }
          if (providerObj.available) {
            availableProviders.push(providerObj)
          } else {
            providerObj.violation = apiProvider.rulesApplied[0].message
            unavailableProviders.push(providerObj)
          }
        })

      return { availableProviders, unavailableProviders }
    },
    [jobid]
  )

  const handleSelectChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const selectedId = event.target.value
      const selectedProvider = providerList.find((p) => p.id === selectedId)
      if (selectedProvider) {
        setSelectedProviderid(selectedId ? String(selectedId) : "")
        onChange(Number(selectedId))
      }
    },
    [providerList, onChange]
  )

  const renderProviders = useCallback((providers: Provider[]) => {
    return providers.map((provider) => (
      <MenuItem
        value={provider.id}
        key={provider.id}
        className={css.providerName}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className={css.statusDot}
            style={{ backgroundColor: provider.color }}
          ></span>
          <ListItemText className={css.providerName} primary={provider.name} />
        </div>
      </MenuItem>
    ))
  }, [])

  if (error) {
    return <div>Error loading providers</div>
  }

  return (
    <FormControl variant="outlined" className={css.formControl}>
      <Select
        id="grouped-select"
        value={loadingProviders ? "" : selectedProviderid}
        defaultValue={selectedProviderid}
        onChange={handleSelectChange}
        onOpen={onOpen}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        disabled={disabled}
        style={{ maxWidth: "200px" }}
        className={classes.customSelect}
      >
        <MenuItem value="" disabled className={css.defaultSelect}>
          Select Provider
        </MenuItem>
        <ListSubheader className={css.availableLabel}>Available</ListSubheader>
        {loadingProviders ? (
          <MenuItem disabled>
            <ListItemText>Loading providers...</ListItemText>
          </MenuItem>
        ) : availableProviderList && availableProviderList.length > 0 ? (
          renderProviders(availableProviderList)
        ) : (
          <MenuItem disabled className={css.noProviders}>
            No Available Providers
          </MenuItem>
        )}
        <ListSubheader className={css.availableLabel}>
          Unavailable
        </ListSubheader>
        {loadingProviders ? (
          <MenuItem disabled>
            <ListItemText>Loading providers...</ListItemText>
          </MenuItem>
        ) : unavailableProviderList && unavailableProviderList.length > 0 ? (
          renderProviders(unavailableProviderList)
        ) : (
          <MenuItem disabled className={css.noProviders}>
            No Unavailable Providers
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

export default memo(ProvidersCustomSelectComponent)
