import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useMemo,
} from "react"
import css from "./CalendarListView.module.scss"
import AssignmentContentModal from "./AssignmentContentModal"
import { format, parseISO, addDays, isSameDay } from "date-fns"
import JobsTableModal from "./JobsTableModal/JobsTableModal"
import {
  Provider,
  Assignment,
  IRequest,
  IQuickProvider,
} from "@app/containers/spa/WhiteboardCalendar/data"
import { cloneDeep } from "lodash"
import { Flag } from "@material-ui/icons"
import RequestModal from "./RequestModal"
import {
  AssignmentProviderElement,
  DayOffProviderElement,
  RequestProviderElement,
  AssignmentJobElement,
  VacationProviderElement,
} from "./CalendarListDayElements"
import {
  ProcessedHoliday,
  getHolidaysOffDate,
} from "@app/services/getOrderedHolidays"
import { getGroupedDayAssignments } from "@app/services/getGroupedDayAssignments"
import { select, useDispatch, useSelector } from "@app/models"
import { getUnassignedJobsByDay } from "@app/services/getUnassignedEvent"
import cx from "classnames"
import api from "@app/services/api"
import { handleApiError } from "@app/utils"
import { DefaultCalendarColor } from "@app/utils/constants"
import NotesModal from "./NotesModal"
import { getDailyNotes } from "@app/services/getDailyNotes"
import { getDailyScheduleChanges } from "@app/services/getDailyScheduleChanges"
import ScheduleChangesModal from "./ScheduleChangesModal"
import {
  handleMultipleAssignment,
  createSingleQuickAssignment,
  handleDeleteQuickMultipleAssignments,
} from "@app/services/quickAssignments"
import { getProviderIds } from "./CalendarListDayElements/AssignmentJobElement/utils/getIsAssignmentFiltered"
import { isHighlightedChange } from "@app/services/getAdditionalAssignmentHighlighted"
import { optimisticDeleteEvent } from "@app/containers/spa/WhiteboardCalendar/utils/calendarUpdates"

const processAssignmentsByDate = (
  unProcessedAssignments: AssignmentBaseType[]
) => {
  const preprocessedAssignments: { [date: string]: Assignment[] } = {}
  unProcessedAssignments?.forEach((assignment) => {
    const dateKey = format(parseISO(assignment.edate), "yyyy-MM-dd")
    if (!preprocessedAssignments[dateKey]) {
      preprocessedAssignments[dateKey] = []
    }
    preprocessedAssignments[dateKey].push(assignment)
  })
  return preprocessedAssignments
}

type ListToDisplay = {
  id: string | number
  name: string
  color: string | null | undefined
  job_days_type?: JobDayType[]
}

interface CalendarProps {
  view: "Day" | "Week" | "Month"
  selectedDate: string
  periodCount: number
  jobsData: JobAssignment[]
  providersData: Provider[]
  notes: Note[]
  flags: ScheduleDateType[]
  dayoffs: DayOff[]
  vacations: Vacation[]
  requests: IRequest[]
  daysOffTypes: any
  requestStatus: any
  orderedHolidays: ProcessedHoliday[]
  refreshAssignments: () => void
  refreshFlags: () => void
  quickMode: boolean
  selectedProvider?: Provider
  setQuickAssigneePanelOpen: React.Dispatch<React.SetStateAction<boolean>>
  scheduleChanges: ScheduleChange[]
}

const formatDate = (date: string): string =>
  format(parseISO(date), "yyyy-MM-dd")

const ListViewCalendar = ({
  view,
  selectedDate,
  periodCount,
  jobsData,
  providersData,
  flags,
  dayoffs,
  vacations,
  requests,
  orderedHolidays,
  notes,
  refreshAssignments,
  refreshFlags,
  quickMode,
  setQuickAssigneePanelOpen,
  scheduleChanges,
}: CalendarProps) => {
  const { startDate, endDate } = useSelector(
    (state) => state.calendarEvents.calendarConfig.filterOptions
  )

  const {
    jobsFilteredAssignments: jobsFilteredEvents,
    jobsFilteredDraftEvents: jobsFilteredDrafts,
    providersFilteredAssignments: providersFilteredEvents,
    providersFilteredDraftEvents: providersFilteredDrafts,
  } = useSelector(select.calendarEvents.filteredCalendarData)

  const jobsFilteredAssignments: AssignmentBaseType[] = [
    ...jobsFilteredEvents,
    ...jobsFilteredDrafts,
  ]
  const providersFilteredAssignments: AssignmentBaseType[] = [
    ...providersFilteredEvents,
    ...providersFilteredDrafts,
  ]

  const [isProviderView, setIsProviderView] = useState(false)
  const [selectedAssignment, setSelectedAssignment] =
    useState<AssignmentBaseType>()

  const [flagsByDate, setFlagsByDate] = useState<{
    [date: string]: ScheduleDateType[]
  }>({})
  const datesToShow = calculateDatesToShow(selectedDate, view, periodCount)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalDateOpen, setIsModalDateOpen] = useState(false)
  const [formattedDate, setFormattedDate] = useState<string>("")
  const [selectedRequestid, setSelectedRequestid] = useState<
    number | undefined
  >()
  const [currentDate, setCurrentDate] = useState("")

  const { jobs } = useSelector((state) => state.groupData)

  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)
  const [isScheduleChangesModalOpen, setIsScheduleChangesModalOpen] =
    useState(false)
  const [selectedCurrentDate, setSelectedCurrentDate] = useState("")
  const [selectedCurrentDateStr, setSelectedCurrentDateStr] = useState("")
  const dayoffsByDate: Record<string, DayOff[]> = {}
  const { draft_mode_scheduling } = useSelector(
    (state) => state.calendarEvents.calendarConfig.rulesConfig
  )
  dayoffs?.forEach((dayoff) => {
    if (dayoffsByDate[dayoff.date]) {
      dayoffsByDate[dayoff.date] = [...dayoffsByDate[dayoff.date], dayoff]
    } else {
      dayoffsByDate[dayoff.date] = [dayoff]
    }
  })

  const {
    selectedJob,
    selectedProvider,
    isUnderstaffedJobsHighlightActive,
    isHighlightedChangesActive,
    highlightOptions: { highlightProviders },
    rulesConfig: { compact_list_view: compactListView },
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  const { quickAssignments } = useSelector((state) => state.quickAssignments)

  const providersAreHighlighted = useMemo(
    () => highlightProviders && highlightProviders.length > 0,
    [highlightProviders]
  )

  const requestsByDate: Record<string, IRequestType[]> = {}
  requests?.forEach((request) => {
    const startDate = new Date(request.start_date)
    const endDate = new Date(request.end_date)
    if (startDate > endDate) return

    let beginning = startDate
    while (beginning <= endDate) {
      let index = beginning.toISOString().split("T")[0]
      if (requestsByDate[index]) {
        requestsByDate[index] = [...requestsByDate[index], request]
      } else {
        requestsByDate[index] = [request]
      }
      var newDate = beginning.setDate(beginning.getDate() + 1)
      beginning = new Date(newDate)
    }
  })

  const vacationsByDate: Record<string, Vacation[]> = {}
  vacations?.forEach((vacation: Vacation) => {
    const date = vacation.link_date.link_date
    if (vacationsByDate[date]) {
      vacationsByDate[date] = [...vacationsByDate[date], vacation]
    } else {
      vacationsByDate[date] = [vacation]
    }
  })

  const dispatch = useDispatch()

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setIsProviderView(e.target.value === "provider")
    dispatch.calendarEvents.setCalendarListViewMode(
      e.target.value as "job" | "provider"
    )
    setQuickAssigneePanelOpen(false)
    dispatch.calendarEvents.setSelectedProvider(undefined)
    dispatch.calendarEvents.setSelectedJob(undefined)
  }

  const jobsFilteredAssignmentsByDate = useMemo(() => {
    return processAssignmentsByDate(jobsFilteredAssignments)
  }, [jobsFilteredAssignments])

  const providersFilteredAssignmentsByDate = useMemo(() => {
    return processAssignmentsByDate(providersFilteredAssignments)
  }, [providersFilteredAssignments])

  useEffect(() => {
    const preprocessedFlags: { [date: string]: ScheduleDateType[] } = {}
    flags?.forEach((flag) => {
      const dateKey = format(parseISO(flag.link_date.link_date), "yyyy-MM-dd")
      if (!preprocessedFlags[dateKey]) {
        preprocessedFlags[dateKey] = []
      }
      preprocessedFlags[dateKey].push(flag)
    })
    setFlagsByDate(preprocessedFlags)
  }, [flags])

  const showModal = (edate: string, item: any, jobid: number) => {
    const formattedDate = formatDate(edate)
    let dayAssignments: AssignmentBaseType[] = []

    if (isProviderView) {
      dayAssignments = providersFilteredAssignmentsByDate[formattedDate] || []
    } else {
      dayAssignments = jobsFilteredAssignmentsByDate[formattedDate] || []
    }

    let fullAssignment = null
    for (let assignment of dayAssignments) {
      if (assignment.jobid === jobid && assignment.edate === edate) {
        fullAssignment = assignment
        break
      }
      const additionalMatch = assignment.additional_event_assignments?.find(
        (add) => add.jobid === jobid || add.eventid === jobid
      )
      if (additionalMatch) {
        fullAssignment = {
          ...assignment,
          additional_event_assignments: [additionalMatch],
        }
        break
      }
    }

    if (fullAssignment) {
      setSelectedAssignment(cloneDeep(fullAssignment))
    } else if (!isProviderView && item.id) {
      setSelectedAssignment({ jobid: item.id, providerid: "", edate } as any)
    } else {
      setSelectedAssignment(undefined)
    }
    setIsModalOpen(true)
  }

  const hideModal = () => {
    setSelectedAssignment(undefined)
    dispatch.calendarEvents.getCalendarDataWithoutEvents()
    setIsModalOpen(false)
  }

  const showDateModal = (date: string) => {
    const formattedDate = formatDate(date)
    setFormattedDate(formattedDate)
    setCurrentDate(date)
    setIsModalDateOpen(true)
  }

  const hideDateModal = () => {
    refreshFlags()
    setIsModalDateOpen(false)
  }

  const handleNotesModalOpen = (edate: string) => {
    setIsNotesModalOpen(true)
    const parsedDate = format(parseISO(edate), "EEEE, MM/dd/yy")
    setSelectedCurrentDate(parsedDate)
  }

  const handleNotesModalClose = () => {
    setIsNotesModalOpen(false)
    setSelectedCurrentDate("")
  }

  const handleScheduleChangesModalOpen = (edate: string) => {
    setIsScheduleChangesModalOpen(true)
    setSelectedCurrentDateStr(edate)
  }

  const handleScheduleChangesModalClose = () => {
    setIsScheduleChangesModalOpen(false)
    setSelectedCurrentDateStr("")
  }

  const getIsProviderCardDisabled = (jobId: number) => {
    return Boolean(selectedJob) && jobId !== selectedJob?.jobid
  }

  const getNoAssignmentText = (
    jobDayType: JobDayType,
    isProviderView: boolean
  ) => {
    if (jobDayType && jobDayType.assign === null) {
      return "Unavailable"
    } else if (isProviderView) {
      return "No assignments"
    } else {
      return "No providers"
    }
  }

  const renderNumberOfUnderstaffedJobs = (
    dates: string[],
    jobId: number,
    job_days_type: JobDayType[] | undefined
  ) => {
    let counter = 0
    dates.forEach((date) => {
      let internalDayCounter = 0
      const jobsFilteredDayAssignments =
        jobsFilteredAssignmentsByDate[date] || []

      const groupedDayJobsFilteredDayAssignments = getGroupedDayAssignments(
        jobsFilteredDayAssignments
      )
      groupedDayJobsFilteredDayAssignments?.forEach((assignment) => {
        if (assignment.job.jobid === jobId) {
          const multipleAssignmentSettings = jobs
            .find((job) => job.jobid === assignment.jobid)
            ?.multipleAssignmentsSettings?.find(
              (setting) => setting.date === assignment.edate
            )
          if (assignment.draft_eventid) {
            const count = assignment.additional_event_assignments.length + 1

            if (
              multipleAssignmentSettings?.minimum &&
              count < multipleAssignmentSettings.minimum
            ) {
              counter = counter + 1
            }
          } else {
            const currentAssignedCount =
              assignment.additional_event_assignments.length + 1
            if (
              isUnderstaffedJobsHighlightActive &&
              multipleAssignmentSettings &&
              currentAssignedCount < multipleAssignmentSettings.minimum
            ) {
              counter = counter + 1
            }
          }

          internalDayCounter = internalDayCounter + 1
        }
      })

      const dayName = format(parseISO(date), "EEEE")
      const jobDayType = job_days_type
        ? job_days_type.find((day: JobDayType) => day.day === dayName)
        : undefined

      const tableCellUnavailable =
        jobDayType &&
        getNoAssignmentText(jobDayType, isProviderView) === "Unavailable"

      if (internalDayCounter === 0 && !tableCellUnavailable) {
        counter = counter + 1
      }
    })
    return counter > 0 ? (
      <div className={css.jobUnderstaffedCounter}>{counter}</div>
    ) : null
  }

  const handleQuickModeAssignment = async (
    assignmentDate: string,
    item: AssignmentBaseType | IQuickProvider
  ) => {
    const jobId = getJobId(isProviderView, selectedJob, item)
    const providerId = getProviderId(isProviderView, selectedProvider, item)

    if (handleQuickModeAssignment.isProcessing) return
    handleQuickModeAssignment.isProcessing = true

    try {
      if (quickMode) {
        await processQuickModeAssignment(
          isProviderView,
          selectedJob,
          selectedProvider,
          assignmentDate,
          jobId,
          providerId
        )
      } else {
        handleNonQuickModeAssignment(
          isProviderView,
          assignmentDate,
          item,
          jobId
        )
      }
    } catch (error) {
      handleApiError
    } finally {
      handleQuickModeAssignment.isProcessing = false
    }
  }

  handleQuickModeAssignment.isProcessing = false

  const getJobId = (isProviderView: boolean, selectedJob: any, item: any) => {
    return isProviderView ? Number(selectedJob?.jobid) : Number(item?.id)
  }

  const getProviderId = (
    isProviderView: boolean,
    selectedProvider: any,
    item: any
  ) => {
    return isProviderView
      ? Number((item as IQuickProvider).id)
      : Number(selectedProvider?.providerid)
  }

  const processQuickModeAssignment = async (
    isProviderView: boolean,
    selectedJob: any,
    selectedProvider: any,
    assignmentDate: string,
    jobId: number,
    providerId: number
  ): Promise<void> => {
    if (
      (isProviderView && !selectedJob?.jobid) ||
      (!isProviderView && !selectedProvider?.providerid)
    ) {
      return
    }

    const isMultipleAssignmentJob =
      isProviderView && selectedJob.multiple_assignments
    try {
      if (isMultipleAssignmentJob) {
        await handleMultipleAssignment(
          jobId,
          providerId,
          assignmentDate,
          providersFilteredAssignmentsByDate,
          draft_mode_scheduling,
          dispatch
        )
      } else {
        const existingAssignment = quickAssignments.find((assignment) => {
          const assignmentDateWithoutTime = assignment.edate.split("T")[0]
          return (
            assignment.jobid === jobId &&
            assignmentDateWithoutTime === assignmentDate
          )
        })

        if (existingAssignment) {
          const uniqueIdentifier =
            existingAssignment.eventid || existingAssignment.draft_eventid || 0
          dispatch.quickAssignments.removeMultipleQuickAssignment(
            uniqueIdentifier
          )
        }
        await createSingleQuickAssignment(
          draft_mode_scheduling,
          assignmentDate,
          jobId,
          providerId,
          dispatch
        )
      }
    } catch (error) {
      handleApiError
    }
    refreshAssignments()
  }

  const handleNonQuickModeAssignment = (
    isProviderView: boolean,
    assignmentDate: string,
    item: AssignmentBaseType | IQuickProvider,
    jobId: number
  ) => {
    if (isProviderView) {
      showDateModal(assignmentDate)
    } else {
      showModal(assignmentDate, item as AssignmentBaseType, jobId)
    }
  }

  const handleDeleteMultipleQuickAssign = async (additionalAssignment: any) => {
    const assignmentsForDate =
      providersFilteredAssignmentsByDate[additionalAssignment.edate] || []
    const filteredAssignments = assignmentsForDate.filter(
      (assignment) => assignment.jobid === additionalAssignment.job.jobid
    )
    const multiProviderIds = getProviderIds(filteredAssignments)
    const filteredProviderIds = multiProviderIds.filter(
      (id) => id !== additionalAssignment.providerid
    )
    const updateFunction = additionalAssignment.draft_eventid
      ? api.updateDraftMultipleAssignments
      : api.updateMultipleAssignments
    const response = await updateFunction(
      additionalAssignment.job.jobid,
      additionalAssignment.edate,
      filteredProviderIds
    )
    optimisticDeleteEvent(
      {
        edate: additionalAssignment.edate,
        jobid: additionalAssignment.jobid,
      },
      startDate,
      endDate,
      Boolean(additionalAssignment.draft_eventid)
    )
    dispatch.quickAssignments.removeQuickAssignment(
      response.draft_eventid || response.eventid
    )
  }

  const handleDeleteQuickAssign = async (assignment: AssignmentBaseType) => {
    const id = assignment?.draft_eventid || assignment?.eventid
    const deleteAssigment = assignment?.draft_eventid
      ? api.deleteDraftEvent
      : api.deleteEvent

    const totalAssigments = handleDeleteQuickMultipleAssignments(assignment)
    const isMultipleAssignmentJob =
      isProviderView &&
      selectedJob?.multiple_assignments &&
      totalAssigments.length > 1

    if (id) {
      if (isMultipleAssignmentJob) {
        const multiProviderIds = getProviderIds(assignment)
        const filteredProviderIds = multiProviderIds.filter(
          (id) => id !== assignment.providerid
        )

        optimisticDeleteEvent(
          {
            edate: assignment.edate,
            jobid: assignment.jobid,
          },
          startDate,
          endDate,
          Boolean(assignment.draft_eventid)
        )

        const updateFunction = assignment.draft_eventid
          ? api.updateDraftMultipleAssignments
          : api.updateMultipleAssignments
        const response = await updateFunction(
          assignment.jobid,
          assignment.edate,
          filteredProviderIds
        )

        dispatch.quickAssignments.removeQuickAssignment(
          response.draft_eventid || response.eventid
        )
      } else {
        try {
          optimisticDeleteEvent(
            {
              edate: assignment.edate,
              jobid: assignment.jobid,
            },
            startDate,
            endDate,
            Boolean(assignment.draft_eventid)
          )
          deleteAssigment(id)
          dispatch.quickAssignments.removeQuickAssignmentAndViolation(id)
        } catch (error) {
          handleApiError
        }
      }
    }
  }

  const renderAssignmentForDate = useCallback(
    (date: string, item: any) => {
      let noAssignmentKeyCounter = 0
      const formattedDate = format(parseISO(date), "yyyy-MM-dd")
      const providersFilteredDayAssignments =
        providersFilteredAssignmentsByDate[formattedDate] || []

      const jobsFilteredDayAssignments =
        jobsFilteredAssignmentsByDate[formattedDate] || []

      const groupedDayJobsFilteredDayAssignments = getGroupedDayAssignments(
        jobsFilteredDayAssignments
      )

      const dayName = format(parseISO(date), "EEEE")
      const jobDayType = item.job_days_type
        ? item.job_days_type.find((day: JobDayType) => day.day === dayName)
        : null
      let matchedAssignments = []

      if (isProviderView) {
        const dailyDayoffs = dayoffsByDate[formattedDate] || []
        const dailyRequests = requestsByDate[formattedDate] || []
        const dailyVacations = vacationsByDate[formattedDate] || []

        const dailyScheduleChanges = getDailyScheduleChanges(
          scheduleChanges,
          new Date(parseISO(date))
        )

        providersFilteredDayAssignments?.forEach((assignment) => {
          if (assignment.provider.providerid === item.id) {
            const isAssignmentHighlightedProvider = isHighlightedChange(
              isHighlightedChangesActive,
              dailyScheduleChanges,
              assignment
            )

            matchedAssignments.push(
              <AssignmentProviderElement
                key={`provider-${assignment.providerid}-${
                  assignment.eventid ?? assignment?.draft_eventid
                }`}
                assignment={assignment}
                showModal={() => showModal(date, item, assignment.jobid)}
                isCardDisabled={getIsProviderCardDisabled(assignment.job.jobid)}
                quickMode={quickMode}
                handleDeleteQuickAssign={() => {
                  handleDeleteQuickAssign(assignment)
                }}
                handleDeleteMultipleQuickAssign={() => {
                  handleDeleteMultipleQuickAssign(assignment)
                }}
                isAssignmentHighlightedProvider={
                  isAssignmentHighlightedProvider
                }
              />
            )
          }

          assignment?.additional_event_assignments?.forEach(
            (additionalAssignment) => {
              if (additionalAssignment.provider.providerid === item.id) {
                matchedAssignments.push(
                  <AssignmentProviderElement
                    key={`provider-${additionalAssignment.providerid}-${additionalAssignment?.id}`}
                    assignment={additionalAssignment}
                    showModal={() =>
                      showModal(date, item, additionalAssignment.job?.jobid)
                    }
                    isCardDisabled={getIsProviderCardDisabled(
                      additionalAssignment?.job?.jobid
                    )}
                    quickMode={quickMode}
                    handleDeleteMultipleQuickAssign={() => {
                      handleDeleteMultipleQuickAssign(additionalAssignment)
                    }}
                  />
                )
              }
            }
          )
        })

        dailyDayoffs?.forEach((dayoff: DayOff) => {
          if (dayoff.providerid === item.id) {
            matchedAssignments.push(
              <DayOffProviderElement
                key={`dayoff-${dayoff.dayoffid}`}
                dayOffTypeName={dayoff.type_name}
                isCardDisabled={Boolean(selectedJob?.jobid)}
              />
            )
          }
        })

        if (dailyVacations.length > 0) {
          dailyVacations.forEach((vacation) => {
            if (vacation.providerid === item.id) {
              matchedAssignments.push(
                <VacationProviderElement
                  isCardDisabled={Boolean(selectedJob)}
                  key={`vacation-${vacation.vacationid}`}
                />
              )
            }
          })
        }

        dailyRequests?.forEach((request: IRequestType) => {
          if (request.providerid === item.id) {
            matchedAssignments.push(
              <RequestProviderElement
                key={`request-${request.requestid}`}
                request={request}
                setSelectedRequestid={() =>
                  setSelectedRequestid(request.requestid)
                }
                isCardDisabled={getIsProviderCardDisabled(request.jobid)}
              />
            )
          }
        })
      } else if (!isProviderView && typeof item !== "string") {
        const dayFlags = flagsByDate[formattedDate] || []
        const dailyUnassigned = getUnassignedJobsByDay(
          jobsData,
          date,
          jobsFilteredDayAssignments
        )

        if (!compactListView) {
          dailyUnassigned?.forEach((unassigned) => {
            if (unassigned.jobid === item.id) {
              matchedAssignments.push(
                <button
                  onClick={() => handleQuickModeAssignment(date, item)}
                  className={cx(css.unassignedContainer, {
                    [css.unassignedFadedContainer]:
                      providersAreHighlighted || isHighlightedChangesActive,
                    [css.unassignedHighlightedContainer]:
                      isHighlightedChangesActive,
                  })}
                  key={`unassigned-${date}-${unassigned.jobid}`}
                >
                  <span className={css.noAssignment}>Unassigned</span>
                  {isUnderstaffedJobsHighlightActive && (
                    <div className={css.understaffedCounter}>{`0/${
                      jobs
                        .find((job) => job.jobid === unassigned.jobid)
                        ?.multipleAssignmentsSettings?.find(
                          (setting) => setting.date === date
                        )?.minimum
                    }`}</div>
                  )}
                </button>
              )
            }
          })
        }

        groupedDayJobsFilteredDayAssignments?.forEach((assignment) => {
          if (assignment.job.jobid === item.id) {
            const dailyScheduleChanges = getDailyScheduleChanges(
              scheduleChanges,
              new Date(parseISO(date))
            )

            const isAssignmentHighlighted = isHighlightedChange(
              isHighlightedChangesActive,
              dailyScheduleChanges,
              assignment
            )

            matchedAssignments.push(
              <AssignmentJobElement
                assignment={assignment}
                quickMode={quickMode}
                showModal={() => showModal(date, item, assignment.jobid)}
                handleDeleteQuickAssign={() =>
                  handleDeleteQuickAssign(assignment)
                }
                key={`job-${assignment.draft_eventid ?? assignment.eventid}-${
                  assignment.jobid
                }-${assignment.providerid}`}
                isHighlightedChanges={isAssignmentHighlighted}
              />
            )
          }
        })

        dayFlags?.forEach((flag) => {
          if (flag.job.abbrev === item.name) {
            matchedAssignments.push(
              <Fragment key={`job-${flag.jobid}-${flag.link_dateid}`}>
                <div
                  onClick={() => showModal(date, item, flag.jobid)}
                  className={css.JobContainer}
                >
                  <Flag style={{ color: "red" }} className={css.flagIcon} />
                </div>
              </Fragment>
            )
          }
        })
      }

      if (matchedAssignments.length === 0) {
        const tableCellUnavailable =
          getNoAssignmentText(jobDayType, isProviderView) === "Unavailable"

        matchedAssignments.push(
          <button
            onClick={() => {
              if (jobDayType && jobDayType.assign === null) {
                return
              }
              handleQuickModeAssignment(date, item)
            }}
            className={cx(css.emptyContainer, {
              [css.disabledContainer]: tableCellUnavailable,
            })}
            key={`no-assignment-${date}-${noAssignmentKeyCounter++}`}
            disabled={tableCellUnavailable}
          >
            <p className={css.noAssignment}>
              {compactListView
                ? ""
                : getNoAssignmentText(jobDayType, isProviderView)}
            </p>
            {isUnderstaffedJobsHighlightActive && !tableCellUnavailable && (
              <div className={css.understaffedCounter}>{`0/${
                jobs
                  .find((job) => job.jobid === item.id)
                  ?.multipleAssignmentsSettings?.find(
                    (setting) => setting.date === date
                  )?.minimum
              }`}</div>
            )}
          </button>
        )
      }

      return matchedAssignments
    },
    [
      isProviderView,
      providersFilteredAssignmentsByDate,
      jobsFilteredAssignmentsByDate,
    ]
  )

  const listToDisplay: ListToDisplay[] = useMemo(
    () =>
      isProviderView
        ? providersData.map((provider) => ({
            id: provider.providerid,
            name: provider.display_name,
            color: provider.provider_color,
          }))
        : jobsData.map((job) => ({
            id: job.jobid,
            name: job.abbrev,
            color: job.color,
            job_days_type: job.job_day_types,
          })),
    [isProviderView, providersData, jobsData]
  )

  const noInformationAvailable = listToDisplay.length === 0

  return (
    <div className={css.listViewContainer}>
      <div className={css.tableScroll}>
        <table className={css.listViewTable}>
          <thead>
            <tr>
              <th className={css.listViewHeader}>
                <select
                  onChange={handleSelectChange}
                  className={css.listViewSelect}
                >
                  <option value="job">Job</option>
                  <option value="provider">Provider</option>
                </select>
              </th>
              {datesToShow.map((date, index) => {
                const parsedDate = compactListView
                  ? format(parseISO(date), "EEE")
                  : format(parseISO(date), "eee, MMM d")
                const holidaysByDate = getHolidaysOffDate(date, orderedHolidays)
                const isToday = isSameDay(new Date(parseISO(date)), new Date())
                const dailyNotes = getDailyNotes(
                  notes,
                  new Date(parseISO(date))
                )
                const dailyScheduleChanges = getDailyScheduleChanges(
                  scheduleChanges,
                  new Date(parseISO(date))
                )

                return (
                  <th
                    key={index}
                    className={cx(css.listViewHeaderDate, {
                      [css.listViewWeekend]:
                        parsedDate.includes("Sat") ||
                        parsedDate.includes("Sun"),
                      [css.todayOutlined]: isToday,
                    })}
                  >
                    <div className={css.listViewHeaderDateContainer}>
                      <div className={css.listViewHeaderDateTitleContainer}>
                        <button
                          className={css.listViewHeaderDateParseDate}
                          onClick={() => showDateModal(date)}
                        >
                          <span>{parsedDate}</span>
                          {compactListView && (
                            <span>{format(parseISO(date), "MMM d")}</span>
                          )}
                        </button>
                        {dailyScheduleChanges.length > 0 ? (
                          <button
                            onClick={() => handleScheduleChangesModalOpen(date)}
                            className={css.trackChanges}
                          >
                            <img src="/art/calendar/delta.png" />
                          </button>
                        ) : null}
                        {dailyNotes.length > 0 ? (
                          <button
                            onClick={() => handleNotesModalOpen(date)}
                            className={css.noteButton}
                          >
                            Notes
                          </button>
                        ) : null}
                      </div>
                      {holidaysByDate.map((holidayByDate) => (
                        <div
                          className={cx(css.listViewHeaderDateHoliday, {
                            [css.listViewHeaderDateWeekendHoliday]:
                              parsedDate.includes("Saturday") ||
                              parsedDate.includes("Sunday"),
                          })}
                          key={holidayByDate.holidayId}
                        >
                          {compactListView
                            ? holidayByDate.abbrev
                            : holidayByDate.name}
                        </div>
                      ))}
                    </div>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {noInformationAvailable ? (
              <tr>
                <td
                  colSpan={datesToShow.length + 1}
                  className={css.noInformationCell}
                >
                  No information available
                </td>
              </tr>
            ) : (
              listToDisplay.map((item, index) => (
                <tr key={index}>
                  <td
                    className={cx(css.listViewCell, {
                      [css.miniListViewCell]: compactListView,
                    })}
                  >
                    {isProviderView ? (
                      <div className={css.headListProvider}>
                        <div className={css.providerWrapper}>
                          <div
                            className={css.providerCellCircle}
                            style={{
                              background: item.color || DefaultCalendarColor,
                            }}
                          />
                          <div className={css.providerLabelName}>
                            {item.name}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={css.headListJob}>
                        <div
                          className={css.jobCellRectangle}
                          style={{
                            background: item.color || DefaultCalendarColor,
                          }}
                        />
                        <div className={css.providerLabelName}>{item.name}</div>
                        {isUnderstaffedJobsHighlightActive &&
                          renderNumberOfUnderstaffedJobs(
                            datesToShow,
                            Number(item.id),
                            item.job_days_type
                          )}
                      </div>
                    )}
                  </td>

                  {datesToShow.map((date, dateIndex) => {
                    return (
                      <td
                        key={`${index}-${dateIndex}`}
                        className={cx(css.listViewCellJobs, {
                          [css.miniListViewCellJobs]: compactListView,
                        })}
                      >
                        <div
                          className={
                            isProviderView
                              ? css.assignmentProviderContainer
                              : css.assignmentJobsContainer
                          }
                        >
                          <Fragment>
                            {renderAssignmentForDate(date, item)}
                          </Fragment>
                        </div>
                      </td>
                    )
                  })}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {selectedAssignment && (
        <AssignmentContentModal
          show={isModalOpen}
          hideModal={hideModal}
          selectedAssignment={selectedAssignment}
          edate={selectedAssignment.edate}
          jobid={selectedAssignment.jobid}
        />
      )}
      {selectedRequestid && (
        <RequestModal
          requestid={selectedRequestid}
          onHide={() => setSelectedRequestid(undefined)}
        />
      )}
      <JobsTableModal
        centered={true}
        isModalDateOpen={isModalDateOpen}
        onHide={hideDateModal}
        date={formattedDate}
        currentDate={currentDate}
        onCancel={hideDateModal}
        onSave={hideDateModal}
        closeButton={true}
        size="xl"
      />

      <NotesModal
        date={selectedCurrentDate}
        isModalOpen={isNotesModalOpen}
        onCloseModal={handleNotesModalClose}
        closeButton
      />
      <ScheduleChangesModal
        date={selectedCurrentDateStr}
        isModalOpen={isScheduleChangesModalOpen}
        onCloseModal={handleScheduleChangesModalClose}
      />
    </div>
  )
}

const calculateDatesToShow = (
  selectedDate: string,
  view: string,
  periodCount: number
) => {
  let dates = []
  const start = parseISO(selectedDate)
  if (view === "Day") {
    for (let i = 0; i < periodCount; i++) {
      dates.push(format(addDays(start, i), "yyyy-MM-dd"))
    }
  } else if (view === "Week") {
    for (let i = 0; i < periodCount * 7; i++) {
      dates.push(format(addDays(start, i), "yyyy-MM-dd"))
    }
  } else if (view === "Month") {
    for (let i = 0; i < 30 * periodCount; i++) {
      dates.push(format(addDays(start, i), "yyyy-MM-dd"))
    }
  }
  return dates
}

export default ListViewCalendar
